import React from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import Header from "../Components/Header";
import Footer from "./Footer";
const Services = () => {
  return (
    <>
 <Header />
      {/* <!--
End Fixed Navigation
==================================== --> */}

<section class="single-page-header">
	<div class="container">
		<div class="row">
			<div class="col-md-12">
				<h2>Our Services</h2>
				<ol class="breadcrumb header-bradcrumb justify-content-center">
					<li class="breadcrumb-item"><a href="index.html" class="text-white">Home</a></li>
					<li class="breadcrumb-item active" aria-current="page">Our Services</li>
				</ol>
			</div>
		</div>
	</div>
</section>

{/* <!-- ======= Services Section ======= --> */}
<section id="services" className="services">
          <div className="container" data-aos="fade-up">
            <header className="section-header">
              <h2>OUR SERVICES</h2>
              <p>We focus on resolving the IT conundrum for our clients thereby<br />
                enabling them to focus on their core business strengths.</p>
            </header>

            <div className="row gy-4">
              <div
                className="col-lg-4 col-md-6 pb-5"
                data-aos="fade-up"
                data-aos-delay="200"
              >
                <div className="service-box blue">
                  <i className="ri-discuss-line icon"></i>
                  <h3>PYTHON/DJANGO DEVELOPMENT</h3>
                  <p>
                    We have a specialization in Python/Django Development and use it on many govt projects.
                  </p>
                  <a href="#" className="read-more">
                    {/* <span>Read More</span> <i className="bi bi-arrow-right"></i> */}
                  </a>
                </div>
              </div>

              <div
                className="col-lg-4 col-md-6 pb-5"
                data-aos="fade-up"
                data-aos-delay="300"
              >
                <div className="service-box orange">
                  <i className="ri-discuss-line icon"></i>
                  <h3>CLOUD/SAAS DEVELOPMENT</h3>
                  <p>
                    Built on cloud means infrastructure you can trust, expertise-rich solutions, and a full range of business applications to help you drive enterprise transformation.
                  </p>
                  <a href="#" className="read-more">
                    {/* <span>Read More</span> <i className="bi bi-arrow-right"></i> */}
                  </a>
                </div>
              </div>

              <div
                className="col-lg-4 col-md-6 pb-5"
                data-aos="fade-up"
                data-aos-delay="400"
              >
                <div className="service-box green">
                  <i className="ri-discuss-line icon"></i>
                  <h3>WEB/E-COMMERCE DEVELOPMENT</h3>
                  <p>
                    We can develop a quality based website or transfer your existing website to the newer technology.
                  </p>
                  <a href="#" className="read-more">
                    {/* <span>Read More</span> <i className="bi bi-arrow-right"></i> */}
                  </a>
                </div>
              </div>

              <div
                className="col-lg-4 col-md-6"
                data-aos="fade-up"
                data-aos-delay="500"
              >
                <div className="service-box red">
                  <i className="ri-discuss-line icon"></i>
                  <h3>SERVER/CLOUD SUPPORT</h3>
                  <p>
                    We provide knowledgeable, certified, in-house staff with technical experiences ranging from 3 to 20 years.Unlimited 24×7 coverage.
                  </p>
                  <a href="#" className="read-more">
                    {/* <span>Read More</span> <i className="bi bi-arrow-right"></i> */}
                  </a>
                </div>
              </div>

              <div
                className="col-lg-4 col-md-6"
                data-aos="fade-up"
                data-aos-delay="600"
              >
                <div className="service-box purple">
                  <i className="ri-discuss-line icon"></i>
                  <h3>CREATIVE DESIGN</h3>
                  <p>
                    Creative Design There are many tools, which can be used for making the website designed into a proper manner.
                  </p>
                  <a href="#" className="read-more">
                    {/* <span>Read More</span> <i className="bi bi-arrow-right"></i> */}
                  </a>
                </div>
              </div>

              <div
                className="col-lg-4 col-md-6"
                data-aos="fade-up"
                data-aos-delay="700"
              >
                <div className="service-box pink">
                  <i className="ri-discuss-line icon"></i>
                  <h3>WORDPRESS EXPERTS</h3>
                  <p>
                    We pride ourselves on being a leading WordPress solutions provider while still being small and agile enough to truly partner with our clients.
                  </p>
                  <a href="#" className="read-more">
                    {/* <span>Read More</span> <i className="bi bi-arrow-right"></i> */}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- End Services Section --> */}

{/* <!-- Start Services Section
==================================== --> */}

<section class="services" id="services">
	<div class="container">
		<div class="row justify-content-center">
			{/* <!-- section title --> */}
			<div class="col-xl-6 col-lg-8">
				<div class="title text-center">
					<h2>WHY CHOOSE OUR SERVICES</h2>
					<p>We focus on resolving the IT conundrum for our clients thereby<br />
                      enabling them to focus on their core business strengths.</p>
					<div class="border"></div>
				</div>
			</div>
			{/* <!-- /section title --> */}
		</div>
		<div class="row no-gutters">

			{/* <!-- Single Service Item --> */}
			<div class="col-lg-4 col-sm-6 mb-4 mb-lg-0">
				<div class="service-block p-4 color-bg text-center">
					<div class="service-icon text-center">
						<i class="tf-ion-ios-copy-outline"></i>
					</div>
					<h3>24/7 SUPPORT</h3>
					<p>At Netsparked Web Design, our client relationships do not start at 9am and end at 6am – they stay and we continue to deliver real time support and the quality services that they deserve.</p>
				</div>
			</div>
			{/* <!-- End Single Service Item -->

			<!-- Single Service Item --> */}
			<div class="col-lg-4 col-sm-6 mb-4 mb-lg-0">
				<div class="service-block p-4 text-center">
					<div class="service-icon text-center">
						<i class="tf-ion-ios-alarm-outline"></i>
					</div>
					<h3>COST EFFECTIVENESS</h3>
					<p>Based on your ROI needs and goals, we offer affordable and quality website design services that will sharply reflect your businesses.</p>
				</div>
			</div>
			{/* <!-- End Single Service Item -->

			<!-- Single Service Item --> */}
			<div class="col-lg-4 col-sm-6 mb-4 mb-lg-0">
				<div class="service-block p-4 color-bg text-center">
					<div class="service-icon text-center">
						<i class="tf-ion-ios-book-outline"></i>
					</div>
					<h3>CUSTOMER RELATIONSHIP</h3>
					<p>Not only we provide real time support, we also focus on mastering customer relationship strategies. We strive to maintain healthy relationships with our clients.</p>
				</div>
			</div>
		
			<div class="col-lg-4 col-sm-6 mb-4 mb-lg-0">
				<div class="service-block p-4  text-center">
					<div class="service-icon text-center">
						<i class="tf-ion-ios-briefcase-outline"></i>
					</div>
					<h3>TIMELY DELIVERY</h3>
					<p>LNot only we worked hard to complete the project work successfully, we also believe in timely delivery of the work done with no flaw as per the comfort of the client.</p>
				</div>
			</div>
		
			<div class="col-lg-4 col-sm-6 mb-4 mb-lg-0">
				<div class="service-block p-4 color-bg text-center">
					<div class="service-icon text-center">
						<i class="tf-ion-ios-crop"></i>
					</div>
					<h3>RESULTS DRIVEN</h3>
					<p>LWe believe in laying firm foundations with the right technology that result in prominent website designs.</p>
				</div>
			</div>
			
			<div class="col-lg-4 col-sm-6 mb-4 mb-lg-0">
				<div class="service-block p-4 text-center">
					<div class="service-icon text-center">
						<i class="tf-ion-ios-home-outline"></i>
					</div>
					<h3>HIGH TECHNICAL EXPERTISE</h3>
					<p>At Netsparked Web Design, we have the best designers, developers, quality analysts and project managers. We follow agile processes to ensure quality and timely delivery as required by the client.</p>
				</div>
			</div>
		

		</div> 
	</div> 
</section> 
{/* <!-- Start Team Skills
=========================================== --> */}
<section class="team-skills section-sm" id="skills">
	<div class="container">
		<div class="row justify-content-center">

		
		</div>

		<div class="row justify-content-between">
			<div class="col-md-5">
				<div class="team-skills-content">
					<h2 class="mb-3">Simple To Start</h2>
					<p>We are not only aimed to provide the
best website design and development, but also help you with the most
engaging services to attract your target users.</p>
					<a href="contact.html" class="btn btn-main mt-20">Contact Us</a>
				</div>
			</div>
			<div class="col-md-6">
				<div class="progress-block">
					<ul>
						<li class="mb-3">
							<span>Photoshop</span>
							<div class="progress">
								<div class="progress-bar prog-one">
								</div>
							</div>
						</li>
						<li class="mb-3">
							<span>Web Application</span>
							<div class="progress">
								<div class="progress-bar prog-two">
								</div>
							</div>
						</li>
						<li class="mb-3">
							<span>Andriod Application</span>
							<div class="progress">
								<div class="progress-bar prog-three">
								</div>
							</div>
						</li>
						<li>
							<span>IOS Development</span>
							<div class="progress">
								<div class="progress-bar prog-four">
								</div>
							</div>
						</li>
					</ul>

				</div>
			</div>
		</div> 
    </div>
    </section>
   
    <Footer />
    </>
  );
};

export default Services;
